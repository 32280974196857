import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  CardActions,
  CircularProgress,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import firebase from './firebase';

const CategoryCard = styled(Card)({
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  borderRadius: '12px', // Soften card corners
});

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const database = firebase.database();
    const postsRef = database.ref('posts');

    const unsubscribe = postsRef.on('value', (snapshot) => {
      const postsData = snapshot.val();
      const postsArray = [];

      for (const key in postsData) {
        if (Object.hasOwnProperty.call(postsData, key)) {
          const post = postsData[key];
          postsArray.push({ id: key, ...post });
        }
      }

      setPosts(postsArray);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 6 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}
      >
        Blog Posts
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {posts.map((post) => (
          <Grid item key={post.id} xs={12} sm={6} md={4} lg={3}>
            <CategoryCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              {post.imageUrl && (
                <CardMedia
                  component="img"
                  height="180"
                  image={post.imageUrl}
                  alt={post.title}
                  sx={{ objectFit: 'cover', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: '600', textAlign: 'center' }}>
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
                  Posted on {post.createdAt}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  sx={{ mb: 2 }}
                  dangerouslySetInnerHTML={{ __html: `${post.content.substring(0, 150)}...` }}
                />
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', mb: 2 }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/blog/${post.id}`}
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                >
                  Read More
                </Button>
              </CardActions>
            </CategoryCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Blogs;
