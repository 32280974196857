import { useState, useEffect } from 'react';
import firebase from '../firebase';

const useFetchBlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseRef = firebase.database().ref('blogPosts');
        databaseRef.on('value', (snapshot) => {
          const data = snapshot.val();
          const fetchedBlogPosts = data ? Object.values(data) : [];
          setBlogPosts(fetchedBlogPosts);
          setLoading(false);
        });
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { blogPosts, loading, error };
};

export default useFetchBlogPosts;
